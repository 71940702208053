import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import AllReciters from "./all-reciters";

const TranslationComponent = ({ children }) => {
 
  return <AllReciters/>; // Render the children components
};

export default TranslationComponent;
export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
        featuredPostCount
        postPerPage
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { published: { ne: false } }
        fileAbsolutePath: { regex: "/content/posts/" }
      }
    ) {
      
      nodes {
        ...PostQueryFragment
      }
    }
  }
`
